a:focus,
button:focus {
  outline: 0 solid;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.pagination_disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

body {
  color: #fff;
  background-color: var(--Background);
  line-height: 1.8;
  font-size: 17px;
  font-family: 'Inter', sans-serif;
  background-position: center;
  background-attachment: fixed;
  overscroll-behavior: none;
}

a:hover {
  text-decoration: none
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer-none {
  pointer-events: none;
}

.bg-black {
  background-color: #000;
}

.no-btn {
  background: none;
  border: none;
  padding: 0;
}

.ptr {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.hide-scroll {
  -ms-oveflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
  width: 0;
}

.competition-detail-carousel-img img {
  max-height: 410px;
  object-fit: contain;
}

.winner-podium-title {
  font-size: 2rem;
}

.single-competitions {
  /* height: 185px; */
  background-position: center;
  /* padding: 16px; */
  position: relative;
  z-index: 1;
  -webkit-transition: .3s;
  transition: .3s;
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 5px 5px 5px 5px;
}

.single-competitions img,
.draw-thumbnail img,
.blog-preview-container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
}

.blog-preview-container {
  background-color: #4f4f4f !important;
  overflow: hidden;
}

.blog-preview-title {
  background-color: #313131;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.single-competitions:hover {
  color: #fff;
}

.single-competitions:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .2;
  content: "";
  z-index: -1;
}

/*============= draw Css ============= */
.single-box {
  border: 1px solid #f3a536;
  position: relative;
  /* background: rgb(3, 3, 3); */
  color: #ffffff;
  padding: 2px;
  text-align: center;
  /* margin-bottom: 30px; */
  z-index: 1;
  border-radius: 10px;
  /* overflow: hidden; */
}

.single-box .ribbon {
  z-index: 10;
}

.draw-content-wrap h6 {
  margin-top: 18px;
  font-size: 16px;
  font-weight: 900;
  position: relative;
  z-index: 3;
}

.draw-thumbnail {
  position: relative;
  z-index: 1;
  /* min-height: 205px; */
}

.draw-thumbnail:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  content: "";
  opacity: .2;
}

p.draw-time {
  display: inline-block;
  text-align: center;
  padding: 15px 0 7px;
  font-size: 17px;
  position: relative;
  margin-bottom: 12px;
  -webkit-transition: .3s;
  transition: .3s;
}

p.draw-time:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  width: 80%;
  background: #4a4a4a;
  -webkit-transition: .3s;
  transition: .3s;
}

span.clock .span {
  font-size: 24px;
}

span.clock span {
  display: inline-block;
  font-size: 24px;
  margin: 8px;
  line-height: .8;
  font-weight: 400
}

span.clock .timer-title {
  display: block;
  font-size: 14px;
  -webkit-transition: .3s;
  transition: .3s;
}

.single-box:hover p.draw-time:after {
  background: #f5a623;
}

.single-box:hover p.draw-time,
.single-box:hover .timer-title {
  font-weight: 900;
}

.monthly-based-carousel {
  padding-top: 70px;
}

.monthly-based-carousel>.owl-nav,
.weekly-based-carousel>.owl-nav {
  position: absolute;
  top: 0;
  width: 240px;
  text-align: center;
  z-index: 5;
  overflow: hidden;
  background-color: #434343;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.monthly-based-carousel>.owl-nav button,
.monthly-based-carousel>.owl-nav a,
.weekly-based-carousel>.owl-nav button,
.weekly-based-carousel>.owl-nav a {
  float: left;
  background: #434343;
  border: 0;
  color: white;
  padding: 0 6px;
}

.weekly-based-carousel>.owl-nav {
  max-width: 450px;
}

.weekly-based-carousel>.owl-nav button {
  padding: 13px 12px;
}


.monthly-based-carousel>.owl-nav button:hover,
.monthly-based-carousel>.owl-nav a:hover {
  background: #333;
  cursor: pointer;
}

.monthly-based-carousel>.owl-nav button.owl-next,
.monthly-based-carousel>.owl-nav a.owl-next {
  float: right;
}


.monthly-based-carousel>.owl-nav button i.fa,
.monthly-based-carousel>.owl-nav a i.fa {
  font-size: 22px;
  padding: 13px 12px;
  float: left;
}

.monthly-based-carousel>.owl-nav span {
  margin-top: 10px;
  display: inline-block;
}

.monthly-based-carousel>.owl-dots .owl-dot {
  visibility: hidden;
  z-index: 6;
  position: absolute;
  top: 0;
  Left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.monthly-based-carousel>.owl-dots .owl-dot.active {
  visibility: visible;
}

.monthly-based-carousel>.owl-dots span {
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.podium-box {
  position: relative;
  margin-top: 60px;
  border: 0 solid var(--Primary) !important;
}

.podium-box::after {
  content: "";
  background-color: var(--Primary);
  height: 50px;
  width: 2px;
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
}

.podium-box::before {
  content: "";
  background-color: var(--Primary);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
}

.winner-title-wrapper {
  min-height: 40px;
  text-transform: capitalize;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winner-title {
  color: #fff;
  font-weight: 700;
}

.faqs {
  padding-bottom: 0;
}

div.faqs .card {
  border-radius: 0;
  background-color: transparent;
}

.faqs .card-header button {
  color: #fff;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 14px 10px 0;
  white-space: inherit;
  font-size: 20px;
  font-weight: 500;
  position: relative;
}

.faqs .card-header button:hover,
.faqs .card-header button:focus {
  text-decoration: none;
}

div.faqs .card-header {
  padding: 0;
}

.faqs .card-body {
  color: #d8d8d8;
}

.faqs .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqs .card-header button:after {
  position: absolute;
  top: 50%;
  right: 0;
  font-family: 'Inter', sans-serif;
  height: 40px;
  margin-top: -20px;
  -webkit-transition: .3s;
  transition: .3s;
}

.faqs .card-header button[aria-expanded='true']:after {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.competition-detail .single-competitions {
  height: 100%;
}

.competition-detail img {
  max-height: 400px;
  object-fit: contain;
}

span.radio-num {
  position: absolute;
  left: 17px;
}

.single-nav {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ddd;
}

div.video-box {
  background-color: #2a2a2a;
  padding: 16px;
  border: 2px solid #f5a623;
  border-radius: 10px;
}

.video-nav {
  overflow: hidden;
}

.single-nav {
  background-color: var(--Primary);
  color: #fff !important;
  width: 100%;
  float: left;
  padding: 40px 8px;
  height: 70px !important;
  min-height: auto !important;
  position: relative;
  z-index: 1;
  line-height: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem !important;
  font-weight: 700;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.single-nav:not(:first-child) {
  border-left: 1px solid #2a2a2a;
}

.single-nav:hover {
  color: #fff;
  background-color: var(--Mandy);
}

.single-nav.bg-1 {
  margin-right: 3%;
}

.single-week-box {
  margin-bottom: 60px;
}


html {
  position: relative;
}

.copyright a {
  color: #fff;
}

.copyright a:hover,
.social-links a:hover {
  color: var(--Primary);
}

.social-links .icon {
  height: 40px;
  width: 40px;
  color: var(--Neutral1);
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
}

.copyright-area {
  font-size: 0.9rem;
  padding: 30px 0;
  border-top: 1px solid #333;
}

.copyright-area h5 {
  font-size: 1.2rem;
}

.copyright-area hr {
  border-bottom: 1px solid #333;
}

.copyright-area,
.copyright-area a {
  color: #e6e6e6;
}

.copyright-area a {
  font-size: 1rem;
  display: inline-block;
}

.copyright {
  padding: 12px 0;
}

.copyright-row {
  border-top: 1px solid #333;
}

.content-wrapper {
  min-height: calc(100vh - 280px);
}

.footer-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.text-white {
  color: #fff;
}

.shown {
  display: block !important;
}

.relative {
  position: relative;
}

.w-60 {
  width: 60% !important;
}

a {
  color: #fff;
}

a:hover {
  color: #aaa;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 2;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  /* border: 5px solid; */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 200px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
}

.winner-podium-competition-name {
  line-height: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  margin-top: 10px;
}

.ribbon.gray::before,
.ribbon.gray::after {
  border-color: #555;
}

.ribbon.blue::before,
.ribbon.blue::after {
  border-color: #2980b9;
}

.ribbon.red::before,
.ribbon.blue::after {
  border-color: var(--Primary);
}

.ribbon.orange::before,
.ribbon.orange::after {
  border-color: #f5a623;
}

.ribbon.gray span {
  background-color: gray;
}

.ribbon.blue span {
  background-color: #3498db;
}

.ribbon.red span {
  background-color: var(--Primary);
}

.ribbon.orange span {
  background-color: #f5a623;
}

.red-bg {
  background-color: var(--Primary);
}

.ribbon-top-right {
  top: 0;
  right: 0;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
  color: var(--Primary);
}

.ribbon-top-right::before {
  top: 0;
  left: 13px;
}

.ribbon-top-right::after {
  bottom: 13px;
  right: 0;
}

.ribbon-top-right span {
  left: -5px;
  top: 30px;
  transform: rotate(45deg);
}

.row.navbar-row {
  align-items: center;
}

.fb-like {
  width: 90px;
  height: 24px;
}

@media (min-width:250px) {
  .ribbon-top-right::after {
    bottom: 47px;
    right: 0;
    display: none;
  }

  .ribbon-top-right::before {
    top: 0;
    left: 47px;
  }

  .ribbon-top-right span {
    left: 10px;
    top: 30px;
    transform: rotate(45deg);
  }

  .ribbon span {
    width: 200px;
    padding: 10px 0;
    font-size: 8px;
  }
}

.comp-detail-carousel {
  overflow: hidden;
}

.comp-detail-carousel .carousel-slider {
  max-height: 410px;
  border-radius: 8px !important;

  @media (min-width: 600px) {
    border-radius: 16px !important;
  }
}

.comp-detail-carousel .carousel .thumbs-wrapper {
  margin: 0 30px;
}

.comp-detail-carousel .carousel .thumbs {
  padding: 0;
  margin: 0.8rem 0 0 0;
}

.comp-detail-carousel .carousel .thumb.thumb {
  padding: 0;
  border: 0;
  max-height: 50px;
}

.carousel .thumb img {
  max-height: 50px;
  object-fit: contain;
}

.competition-detail-title {
  font-size: 2rem;
}

.comp-detail-carousel .carousel.carousel-slider {
  height: auto;
}
.comp-detail-timer .countdown-row {
  min-width: auto;
  max-width: 225px;
  margin: 0 auto;
}

.comp-desc-close {
  top: 0;
  bottom: auto;
  border-radius: 0 10px 0 10px;
}

.carousel .control-dots .dot.dot {
  width: 15px;
  height: 15px;
}

.comp-preview-container {
  border-radius: 5px;
  background-color: #313131;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.comp-preview-container.free-comp {
  border: 3px solid orange
}

.single-competitions.free-comp {
  border: 3px solid orange
}

.comp-detail-carousel .carousel .slider.slider {
  height: 100%;
}

.comp-detail-carousel .carousel .slider.slider .aspect-ratio-box {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.img-modal-close-btn {
  position: absolute;
  font-size: 32px;
  background: none;
  cursor: pointer;
  color: #fff;
  border: none;
  z-index: 9999;
  top: 0;
  right: 10px;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.competition-detail-modal-dialog {
  max-width: unset;
  margin: unset;
  min-height: unset;
}

.competition-detail-modal .carousel-slider {
  flex: 1;
}

.competition-detail-modal .carousel-slider .slider-wrapper,
.competition-detail-modal-dialog,
.competition-detail-modal .carousel-slider .slider-wrapper .slider,
.competition-detail-modal .carousel-slider .slider-wrapper .slider .slide,
.competition-detail-modal .carousel-slider .slider-wrapper .slider .slide div picture,
.competition-detail-modal .carousel-slider .slider-wrapper .slider .slide div picture img {
  height: 100%;
}

.competition-detail-modal .carousel:nth-of-type(2) {
  min-height: 50px;
}

.competition-detail-modal .carousel-slider .slider-wrapper .slider .slide div picture {
  display: flex;
  align-items: center;
}

.competition-detail-modal img {
  object-fit: contain;
}

.blog-meta {
  background-color: #313131;
  border-radius: 1rem;
}

.blog-content a,
.website-contents a {
  text-decoration: underline;
}

.blog-content img {
  padding: 0 10px;
}

.how-it-works {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: howitworks;
}

.how-it-works .step {
  position: relative;
  margin-left: 3rem;
}

.how-it-works .step:not(:last-child) {
  padding-bottom: 1rem;
}

.how-it-works .step:not(:last-child)::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #cb132b;
  left: -1.5rem;
  top: 0.5rem;
}

.how-it-works .step::after {
  counter-increment: howitworks;
  content: counter(howitworks);
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #cb132b;
  top: 0.5rem;
  left: -2rem;
  border-radius: 50%;
  font-size: 75%;
  text-align: center;
}

.weekly-based-carousel {
  padding-top: 70px;
  position: relative;
}

.week-numbers {
  padding: 0;
  margin: 0 !important;
}

.week-numbers li {
  list-style: none;
  min-width: 80px;
}

.week-numbers li a {
  background-color: #474747;
}

.gradient-bg {
  background-image: radial-gradient(rgba(91, 91, 91, .4) 10%, #000 90%);
  background-position: center;
  background-attachment: fixed;
}

#dropdown-basic-button {
  background-color: #2a2a2a;
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border: none;
  padding: 12px 20px;
  color: #B0B0B0;
}

#dropdown-basic-button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.dropdown-toggle:after {
  margin-left: auto;
}

.disabled {
  opacity: .5;
  cursor: not-allowed;
}

.chat_message {
  border-radius: 10px;
  width: auto !important;
  max-width: 500px;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 20px;
}

.chat_container {
  height: calc(100vh - 265px);
}

.message--icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}

.me+.me .profile_container,
.admin+.admin .profile_container {
  display: none !important;
}

.draw-content-title {
  word-break: break-word;
}

.more-winners-btn {
  font-size: 12px;
  font-weight: 700;
  bottom: -30px;
  right: 0;
  position: absolute;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  border-radius: 10px 0 10px 0;
  background-color: #df2840;
}

.more-winners-container {
  background-color: var(--Primary);
  height: 0;
  z-index: 1;
  overflow: hidden;
  transition: height .5s ease-in-out;
}

.category-list {
  background: #2a2a2a;
  overflow: auto;
  max-height: 300px;
  list-style: none;
  z-index: 9;
  display: none;
}

.category-list a {
  padding: 9px 20px;
}

.category-list li:hover {
  background-color: #333;
}

.popup-modal {
  background-color: #141414;
  outline: none !important;
  border-radius: 0.2rem;
  font-family: 'Inter', sans-serif !important;
}

.modal-content {
  background-color: transparent;
}

.footer-age-icon {
  border: 3px solid var(--Primary);
  border-radius: 25px;
  background-color: #333;
  font-size: 16px;
  font-weight: bolder;
  color: white;
}

#iubenda_policy .iub_container h1,
#iubenda_policy .iub_container h2,
#iubenda_policy .iub_container h3,
#iubenda_policy .iub_container h4 {
  color: white;
}

#iubenda_policy .iub_base_container.iub_container {
  color: white;
  font-family: 'Inter', sans-serif;
}

#iubenda_policy .iub_base_container.iub_container .iub_content {
  padding: 0 0;
}

#iubenda_policy .box_primary.box_primary {
  background: transparent;
}

#iubenda_policy .box_primary.box_primary h4 {
  color: white;
}

#iubenda_policy .iub_footer a {
  color: white;
}

#iubenda_policy .iub_content a {
  color: white;
}

.copyright-area .active-comp {
  line-height: 260%;
  display: inline;
}

.copyright-area .active-comp:hover {
  color: rgb(170 170 170);
}

.winner>div>picture>img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.single-competitions.instant-comp-green {
  border: 3px solid #6ee032
}

.iw-name {
  max-width: 70px;
}

.iw-ticket-number {
  height: 50px;
  width: 50px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
}

.iw-card-wrapper {
  border: 1px solid #bf0;
  background-color: #313131;
}

.iw-card-content {
  border-left: 1px dashed var(--Primary);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.iw-card-content .iw-card-title {
  font-size: 0.8rem;
  border-bottom: 1px dashed var(--Primary);
}

.iw-drawer {
    position: fixed;
    z-index: 999;
    box-shadow: -1px 5px 10px 1px #313131;
    transition: all 1s ease-in-out;
    right: 0;
    top: 0;
    transform: translateX(10000px);
    border-top: 1px dashed #6c757d;
}

#iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-reject-btn.iubenda-cs-reject-btn {
    border-radius: 0.25rem !important;
    order: 2;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

#iubenda-cs-banner .iubenda-cs-opt-group.iubenda-cs-opt-group button.iubenda-cs-accept-btn, #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary {
    margin-right: 8px !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    border-radius: 0.25rem !important;
}

#iubenda-cs-banner .iubenda-cs-customize-btn.iubenda-cs-customize-btn {
    background-color: #555 !important;
    border-color: #555 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    border-radius: 0.25rem !important;
}

#iubenda-iframe #iubFooterBtnContainer#iubFooterBtnContainer button {
    background-color: #dc3545 !important;
    border-radius: 0.25rem !important;
}

#iubenda-iframe .iub-btn-consent.iub-btn-accept.iub-btn-accept {
    border-radius: 0.25rem !important;
}

#iubenda-iframe .iub-btn-consent.iub-btn-reject.iub-btn-reject {
    border-radius: 0.25rem !important;
}

.iubenda-cs-rationale .iubenda-banner-content.iubenda-custom-content #iubenda-cs-paragraph#iubenda-cs-paragraph .iub-p {
    font-size: 17px !important;
    font-weight: 400 !important;
    font-family: 'Inter', sans-serif !important;
}

.iubenda-cs-rationale .iubenda-banner-content.iubenda-custom-content #iubenda-cs-title#iubenda-cs-title {
    font-size: 17px !important;
    font-family: 'Inter', sans-serif !important;
    margin: 0 0 4px 0 !important;
}

#iubenda-cs-banner .iubenda-banner-content.iubenda-banner-content {
    padding: 8px 16px !important;
}

#iubenda-cs-banner .iubenda-cs-rationale.iubenda-cs-rationale {
    margin: 0 !important;
}

#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale.iubenda-cs-rationale {
    justify-content: center;
    margin: auto !important;
}

.iw-card-wrapper.expanded {
  z-index: 99;
}

.iw-card-wrapper.expanded:hover {
  cursor: pointer;
}

.iw-card-wrapper.expanded::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.iw-card-wrapper.expanded {
  height: auto !important;
}

.iw-card-wrapper.expanded > div {
  transform: scaleY(1);
}

.iw-card-wrapper .loader {
    background: rgb(49 49 49 / 75%);
    align-items: center;
    display: flex;
    justify-content: center;
}
